import appConfig from "../config"

interface props {
  data?: any
  token?: string
  headers?: any
}

async function client(
  endpoint: string,
  { data, token, headers: customHeaders, ...customConfig }: props
) {
  const config = {
    method: data ? "POST" : "GET",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  }

  return window.fetch(`${appConfig.url}/${endpoint}`, config).then(async (response: any) => {
    const data = await response.json()
    if (response.ok) {
      return data
    } else if (response.status === 404) {
      return Promise.reject(response.title)
    } else {
      return Promise.reject(data)
    }
  })
}

export { client }
