/* Dev Ops Configurations*/
var config = {
  url: "https://account.fleetonstreet.com/api/inquiry",
  rk: "6Lciut0aAAAAAMN9ZUvDSZS2wzMQyWZ5lOAkHNlK",
  syncfusionK: "ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFhUX9WcXRUQ2JaV0E=",
}

/* Local Configurations*/
// var config = {
//   url: "https://dev-account.fleetonstreet.com/api/inquiry",
//   rk: "6LdBtt0aAAAAAGtS37APwSR1UGIRd_S06XwL_tjh",
//   syncfusionK:
//     "ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFhUX9WcXRUQ2JaV0E=",
// }

export default config
