import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"

import "./site.css"
import { registerLicense } from '@syncfusion/ej2-base';

import appConfig from "../src/config"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import NotFoundScreen from "./components/not-found/notFoundScreen"
import Inquiry from "./components/inquiry/inquiry"

registerLicense(appConfig.syncfusionK)

const root = ReactDOM.createRoot(document.getElementById("root"))

const App = () => (
  <Router>
    <Routes>
      <Route path="/i/:inquiryFormReference">
        <Route index element={<Inquiry />} />
        <Route path=":embeded" element={<Inquiry />} />
      </Route>
      <Route path="/c" element={<NotFoundScreen title="Client hub coming soon..." />} />
      <Route
        path="*"
        element={<NotFoundScreen title="The resource you were looking for does not exist" />}
      />
    </Routes>
  </Router>
)

root.render(<App />)
