import React from "react";

interface NotFoundScreenProps {
  title: string,
  message: string,
  isEmbeded: boolean
}

const NotFoundScreen = ({ title, message, isEmbeded } : NotFoundScreenProps) => {
  return (
    <>
      <div className="h-screen bg-inherit">
        <div className="sm:max-w-lg pt-28 flex flex-col mx-auto">
          <main
            className={`bg-white rounded-lg flex-grow flex flex-col justify-start max-w-lg w-full mx-auto px-4 sm:px-6 lg:px-8 ${
              isEmbeded ? "" : "shadow-md"
            }`}
          >
            <div className="py-8">
              <div className="flex flex-row justify-center py-4">
                <svg width="130" height="57" viewBox="0 0 130 57">
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0H160V80H0z" transform="translate(-15 -13)"></path>
                    <g>
                      <path
                        fillRule="nonzero"
                        stroke="#747678"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M128 56.5H12.12m-6.556 0H0"
                        transform="translate(-15 -13) translate(16 12)"
                      ></path>
                      <g>
                        <g
                          fillRule="nonzero"
                          stroke="#747678"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        >
                          <path
                            d="M0 15L10.5 0"
                            transform="translate(-15 -13) translate(16 12) translate(35) translate(9 41)"
                          ></path>
                          <path
                            d="M29 15L39.5 0"
                            transform="translate(-15 -13) translate(16 12) translate(35) translate(9 41) matrix(-1 0 0 1 69 0)"
                          ></path>
                        </g>
                        <path
                          fill="#FC0"
                          d="M31.163 3.729l24.66 42.517c.692 1.194.286 2.724-.909 3.417-.38.22-.814.337-1.254.337H4.34c-1.38 0-2.5-1.12-2.5-2.5 0-.44.116-.873.337-1.254l24.66-42.517c.693-1.195 2.223-1.601 3.417-.909.377.219.69.532.909.909z"
                          transform="translate(-15 -13) translate(16 12) translate(35)"
                        ></path>
                        <path
                          stroke="#FFF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M29.864 10.48l6.12 10.493 13.139 22.523c.278.477.117 1.09-.36 1.368-.153.089-.327.136-.504.136H9.741c-.552 0-1-.448-1-1 0-.177.047-.35.136-.504l19.26-33.015c.278-.477.89-.638 1.367-.36.149.087.273.21.36.36z"
                          transform="translate(-15 -13) translate(16 12) translate(35)"
                        ></path>
                      </g>
                    </g>
                    <g
                      stroke="#747678"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    >
                      <path
                        d="M5 0L5 3M5 7L5 10"
                        transform="translate(-15 -13) translate(116 14)"
                      ></path>
                      <path
                        d="M1.5 3.5L1.5 6.5"
                        transform="translate(-15 -13) translate(116 14) rotate(-90 1.5 5)"
                      ></path>
                      <path
                        d="M8.5 3.5L8.5 6.5"
                        transform="translate(-15 -13) translate(116 14) rotate(-90 8.5 5)"
                      ></path>
                    </g>
                    <g
                      stroke="#747678"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    >
                      <path
                        d="M5 0L5 3M5 7L5 10"
                        transform="translate(-15 -13) translate(33 39)"
                      ></path>
                      <path
                        d="M1.5 3.5L1.5 6.5"
                        transform="translate(-15 -13) translate(33 39) rotate(-90 1.5 5)"
                      ></path>
                      <path
                        d="M8.5 3.5L8.5 6.5"
                        transform="translate(-15 -13) translate(33 39) rotate(-90 8.5 5)"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="text-center">
                <h1 className="mt-2 text-xl text-gray-600 tracking-tight">{title}</h1>
                <p className="mt-2 text-base text-gray-500">{message}</p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default NotFoundScreen
