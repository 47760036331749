import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons"
import {
  DatePickerComponent,
  DateTimePickerComponent,
  Inject,
  MaskedDateTime,
} from "@syncfusion/ej2-react-calendars"
import { DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns"
import { NumericTextBoxComponent, TextBoxComponent } from "@syncfusion/ej2-react-inputs"
import moment from "moment"
import { FormEvent, Fragment, useEffect, useState } from "react"
import Recaptcha from "react-google-recaptcha"
import { Oval } from "react-loader-spinner"
import FosLogo from "../../assets/favicon.png"
import { client } from "../../utils/api-client"
import { useAsync } from "../../utils/hooks"
import SuccessMessage from "../success-message/successMessage"
import appConfig from "../../config"
import { InquiryFormModel } from "../../models/inquiryForm"
import React from "react"

interface InquiryFormProps {
  form: InquiryFormModel
  isEmbeded: boolean
}
interface customFields {
  customFieldDefinitionReference: string
  value: string | null
  isCustomField: boolean
  normalizedName: string
  displayName: string
}

const InquiryForm = ({ form, isEmbeded }: InquiryFormProps) => {
  //#region variable declaration
  const { isLoading, run } = useAsync()
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [submitted, setSubmitted] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState("")
  const grecaptchaObject = window.grecaptcha
  const [multiSelectValues, setMultiSelectValues] = useState<{ [key: string]: [] }>()

  const submitBehaviorTypes: { [key: string]: string } = {
    completedMessage: "003E077C-0F59-44B3-81EC-525A905CB615",
    redirectUrl: "99B9DE54-03AC-446D-927F-70C10CD2E2CD",
  }
  //#endregion

  //#region set styles
  const fonts: { [key: string]: string } = {
    Standard: '"Open Sans", "Helvetica Neue", Helvetica, Arial, "Tahoma sans-serif"',
    Wide: "Montserrat, Verdana, sans-serif",
    Narrow: 'Oswald, "Arial Narrow", sans-serif',
    Friendly: 'Nunito, "Comic Sans", sans-serif',
    Traditional: '"PT Serif", "Palatino Linotype", "Times New Roman", serif',
  }

  const buttonStyle: { [key: string]: string } = {
    backgroundColor: form.submitButtonOption.backgroundColor,
    color: form.submitButtonOption.fontColor,
    border: `1px solid ${form.submitButtonOption.borderColor}`,
    borderRadius: "10px",
  }
  //#endregion

  //#region handle captcha change
  function handleRecaptchaChange(value: string) {
    setRecaptchaToken(value)
  }
  //#endregion

  //#region handle submit
  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    //#region prepare request
    let arr: customFields[] = []
    const request = {
      inquiryFormReference: form.inquiryFormReference,
      formFields: arr,
      sRef: recaptchaToken,
    }

    form.formFields.forEach((field) => {
      if (field.isVisible) {
        var input = document.getElementsByName(field.normalizedName).item(0) as HTMLInputElement
        let fieldValue = input.value

        if (field.dataType.isDateTimeType && fieldValue) {
          fieldValue = toUtcDateTimeFormat(fieldValue)
        } else if (field.dataType.isDateType && fieldValue) {
          fieldValue = toUtcDateFormat(fieldValue)
        } else if (field.dataType.isBooleanType) {
          fieldValue = String(input.checked)
        } else if (field.dataType.isDropDown && field.isMultiSelect) {
          const selectedOptions = multiSelectValues?.[field.normalizedName] ?? []
          fieldValue = ""
          selectedOptions.forEach((value: string, idx: number) => {
            fieldValue = fieldValue + value
            if (idx < selectedOptions.length - 1) fieldValue += ","
          })
        }
        var inputField = {
          customFieldDefinitionReference: field.definitionReference,
          value: fieldValue,
          isCustomField: field.isCustomField,
          normalizedName: field.normalizedName,
          displayName: field.displayName,
        }
        request.formFields.push(inputField)
      }
    })
    //#endregion

    //#region save
    run(
      client("SaveInquiryForm", { data: request }).then((response) => {
        handleResponse(response)
      })
    )
    //#endregion
  }
  //#endregion

  //#region handle response
  function handleResponse(response: any) {
    if (response.isSuccessful) {
      handleAfterSubmit()
    } else if (response.code === "form_validation") {
      // reset recaptcha
      grecaptchaObject?.reset()
      const errors: any = {}
      response.messages.forEach(({ property, description }: any) => {
        errors[property] = description
      })
      setErrors(errors)
    }
  }

  function handleAfterSubmit() {
    if (isEmbeded) {
      setSubmitted(true)
    } else {
      if (
        form.submitOption.afterSubmitBehavior.submitBehaviorReference ===
        submitBehaviorTypes.completedMessage
      ) {
        setSubmitted(true)
      } else {
        window.open(form.submitOption.afterSubmitBehavior.redirectUrl, "_self")
      }
    }
  }
  //#endregion

  const handleMultiSelect = (e: any) => {
    let temp = { ...multiSelectValues }
    temp[e.target.id] = e.value
    setMultiSelectValues(temp)
  }

  //#region return
  return (
    <div>
      <style>{`\
        .custom-input{
          background-color: ${form.fieldOption.backgroundColor} !important;
          border: 1px solid ${form.fieldOption.borderColor} !important;
          border-radius: ${getBorderRadius(form.fieldOption.style)} !important;
          padding-top: 3px;
          padding-bottom: 3px;
        }
        .custom-checkbox{
          background-color: ${form.fieldOption.backgroundColor} !important;
          border: 1px solid ${form.fieldOption.borderColor} !important;
          border-radius: 4px;
        }
      `}</style>

      {submitted === false && (
        <div className={`sm:w-full ${isEmbeded ? "" : "mt-4"}`}>
          <form id="inquiry-form" onSubmit={handleSubmit}>
            <div
              className={`sm:rounded-lg ${isEmbeded ? "" : "shadow bg-white sm:max-w-md"}`}
              style={{
                fontFamily: fonts[form.formFont.style],
                color: form.formFont.color,
              }}
            >
              <div className={`p-4 ${isEmbeded ? "" : "border-b border-gray-200"}`}>
                <div className="flex justify-between items-center flex-wrap sm:flex-nowrap">
                  <div className="">
                    <h3 className="text-lg leading-6 font-medium">
                      {form.formHeading.primaryHeadingName}
                    </h3>
                    <h4 className="text-xs">{form.formHeading.secondaryHeadingName}</h4>
                  </div>
                </div>
              </div>

              <div className="px-4 pb-4 grid grid-cols-2 gap-2">
                {form.formFields.map(
                  (field) =>
                    field.isVisible && (
                      <div key={field.normalizedName} className="col-span-2">
                        <label htmlFor={field.normalizedName} className="mt-1 text-sm leading-6">
                          {field.displayName}
                        </label>

                        {field.dataType.isTextType && field.options.length === 0 && (
                          <TextBoxComponent
                            id={field.normalizedName}
                            name={field.normalizedName}
                            placeholder={field.isRequired ? "Required" : "Optional"}
                            cssClass="e-outline custom-input"
                            floatLabelType="Never"
                          />
                        )}
                        {field.dataType.isTextType && field.options.length > 0 && (
                          <DropDownListComponent
                            id={field.normalizedName}
                            name={field.normalizedName}
                            placeholder={field.isRequired ? "Required" : "Optional"}
                            dataSource={field.options.map((m) => m.optionValue)}
                            popupHeight="220px"
                            cssClass="e-outline custom-input"
                          />
                        )}
                        {field.dataType.isDropDown && field.isMultiSelect === false && (
                          <Fragment>
                            <DropDownListComponent
                              id={field.normalizedName}
                              name={field.normalizedName}
                              placeholder={field.isRequired ? "Required" : "Optional"}
                              dataSource={field.options.map((m) => m.optionValue)}
                              popupHeight="220px"
                              cssClass="e-outline custom-input"
                            />
                          </Fragment>
                        )}
                        {field.dataType.isDropDown && field.isMultiSelect && (
                          <MultiSelectComponent
                            id={field.normalizedName}
                            name={field.normalizedName}
                            placeholder={field.isRequired ? "Required" : "Optional"}
                            dataSource={field.options.map((m) => m.optionValue)}
                            popupHeight="220px"
                            cssClass="e-outline custom-input"
                            onChange={handleMultiSelect}
                          />
                        )}
                        {field.dataType.isTextAreaType && (
                          <TextBoxComponent
                            multiline={true}
                            id={field.normalizedName}
                            name={field.normalizedName}
                            placeholder={field.isRequired ? "Required" : "Optional"}
                            cssClass="e-outline custom-input"
                            floatLabelType="Never"
                          />
                        )}
                        {field.dataType.isDateType && (
                          <DatePickerComponent
                            format="d MMM yyyy"
                            enableMask={true}
                            id={field.normalizedName}
                            name={field.normalizedName}
                            placeholder={field.isRequired ? "Required" : "Optional"}
                            cssClass="e-outline custom-input"
                          >
                            <Inject services={[MaskedDateTime]} />
                          </DatePickerComponent>
                        )}
                        {field.dataType.isDateTimeType && (
                          <DateTimePickerComponent
                            format="d MMM yyyy hh:mm a"
                            enableMask={true}
                            id={field.normalizedName}
                            name={field.normalizedName}
                            placeholder={field.isRequired ? "Required" : "Optional"}
                            cssClass="e-outline custom-input"
                          >
                            <Inject services={[MaskedDateTime]} />
                          </DateTimePickerComponent>
                        )}
                        {field.dataType.isNumberType && (
                          <NumericTextBoxComponent
                            format="###.###"
                            id={field.normalizedName}
                            name={field.normalizedName}
                            placeholder={field.isRequired ? "Required" : "Optional"}
                            cssClass="e-outline custom-input"
                          ></NumericTextBoxComponent>
                        )}
                        {field.dataType.isDecimalType && (
                          <NumericTextBoxComponent
                            step={0.1}
                            decimals={2}
                            id={field.normalizedName}
                            name={field.normalizedName}
                            placeholder={field.isRequired ? "Required" : "Optional"}
                            cssClass="e-outline custom-input"
                          ></NumericTextBoxComponent>
                        )}
                        {field.dataType.isBooleanType && (
                          <div className="">
                            <CheckBoxComponent
                              id={field.normalizedName}
                              name={field.normalizedName}
                              cssClass="e-outline custom-checkbox"
                            ></CheckBoxComponent>
                          </div>
                        )}

                        {errors[field.normalizedName] && (
                          <div className="text-red-500 text-sm">
                            {" "}
                            {errors[field.normalizedName]}{" "}
                          </div>
                        )}
                      </div>
                    )
                )}
              </div>

              <div className="flex flex-row justify-between px-4">
                <Recaptcha
                  sitekey={appConfig.rk}
                  onChange={handleRecaptchaChange}
                  grecaptcha={grecaptchaObject}
                />
              </div>

              {errors.sRef && <div className="text-red-500 text-sm px-4"> {errors.sRef} </div>}
              {errors.formFields && (
                <div className="text-red-500 text-sm px-4"> {errors.formFields} </div>
              )}

              <div className="flex flex-row justify-between p-4">
                <div className="text-gray-400 text-xs hover:text-gray-500 my-auto">
                  {form.showPoweredByFooter && (
                    <Fragment>
                      <img className="h-7 w-auto inline" src={FosLogo} alt="fos" />
                      Powered by{" "}
                      <a
                        className="cursor-pointer font-medium"
                        href="https://fleetonstreet.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        fleetonstreet.com
                      </a>
                    </Fragment>
                  )}
                </div>
                <button
                  type="submit"
                  className="focus:outline-none px-2.5 py-1.5 text-sm flex"
                  style={buttonStyle}
                  disabled={isLoading}
                >
                  {form.submitButtonOption.displayName}
                  {isLoading && (
                    <Oval
                      color="white"
                      secondaryColor={buttonStyle.backgroundColor}
                      height={16}
                      width={16}
                      wrapperClass="ml-2"
                    ></Oval>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {submitted && (
        <SuccessMessage
          title={form.submitOption.afterSubmitBehavior.messageTitle}
          message={form.submitOption.afterSubmitBehavior.message}
          isEmbeded={isEmbeded}
          showFooter={form.showPoweredByFooter}
        />
      )}
    </div>
  )
  //#endregion

  //#region get border radius
  function getBorderRadius(style: string) {
    switch (style) {
      case "Standard":
        return "5px"
      case "Rounded":
        return "20px"
      default:
        return "0"
    }
  }
  //#endregion
}

//#region date formats
function toUtcDateTimeFormat(date: string) {
  if (date == null || date === "undefined" || date.length <= 0) {
    return ""
  }
  const zonedDate = moment(date, "D MMM YYYY hh:mm aa")
  return moment.utc(zonedDate).format("YYYY-MM-DDTHH:mm:ss[Z]")
}
function toUtcDateFormat(date: string) {
  if (date == null || date === "undefined" || date.length <= 0) {
    return ""
  }
  const zonedDate = moment(date, "D MMM YYYY")
  return moment(zonedDate).format("YYYY-MM-DD")
}
//#endregion

export default InquiryForm
