import React from "react"
import FosLogo from "../../assets/favicon.png"

interface SuccessMessageProps {
  title: string,
  message: string,
  isEmbeded: boolean,
  showFooter: boolean
}

const SuccessMessage = ({title, message, isEmbeded, showFooter}: SuccessMessageProps) => {
  return (
      <div className="h-screen bg-inherit">
        <div className="sm:max-w-lg pt-28 flex flex-col mx-auto">
          <main
            className={`bg-white rounded-lg flex-grow flex flex-col justify-start max-w-lg w-full mx-auto px-4 sm:px-6 lg:px-8 ${
              isEmbeded ? "" : "shadow-md"
            }`}
          >
            <div className="py-6">
              <div className="text-left">
                <h1 className="mt-2 text-xl text-gray-600 tracking-tight">
                  {title ? title : "Your message is sent"}
                </h1>
                <p className="mt-4 text-sm text-gray-500">
                  {message ? message : "Thank you for talking to us"}
                </p>
              </div>
              {showFooter && (
                <div className="text-right">
                  <p className="mt-8 text-xs text-gray-400">
                    <img className="h-7 w-auto inline" src={FosLogo} alt="fos" />
                    Powered by
                    <a
                      href="https://fleetonstreet.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="font-medium cursor-pointer"
                    >
                      {" "}
                      fleetonstreet.com{" "}
                    </a>
                  </p>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
  )
}

export default SuccessMessage
