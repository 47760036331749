import { useEffect } from "react"
import { useAsync } from "../../utils/hooks"
import { ThreeDots } from "react-loader-spinner"
import NotFoundScreen from "../not-found/notFoundScreen"
import InquiryForm from "../inquiry-form/inquiryForm"
import { client } from "../../utils/api-client"
import { useParams } from "react-router-dom"
import React from "react"

function Inquiry() {
  //#region variable declaration
  const { isLoading, isError, run, data, isSuccess } = useAsync()
  //#endregion

  //#region get url paramaters
  const params = useParams()
  const inquiryFormReference = params.inquiryFormReference

  let isEmbeded = false

  if (params.embeded === "true") isEmbeded = true
  //#endregion

  //#region get inquiry form
  useEffect(() => {
    run(client(`GetInquiryForm?inquiryFormReference=${inquiryFormReference}`, {}))
  }, [inquiryFormReference, run])
  //#endregion

  //#region return
  return (
    <div
      className={`block min-h-screen ${isEmbeded ? "" : "py-6 sm:px-6 lg:px-8"}`}
      style={{
        backgroundColor: isEmbeded ? "inherit" : data?.standaloneFormOption.backgroundColor,
      }}
    >
      <div
        className={`sm:w-full ${isEmbeded ? "" : "mt-4 sm:max-w-md"}`}
        style={{ marginLeft: isEmbeded ? "0" : "auto", marginRight: isEmbeded ? "0" : "auto" }}
      >
        {isLoading && !isEmbeded && (
          <div style={{ marginTop: "35%" }}>
            <ThreeDots
              color="#9ca3af"
              height={80}
              width={80}
              wrapperClass="flex-row justify-center"
            ></ThreeDots>
            <h2 className="text-center pt-4 text-gray-500">Loading please wait...</h2>
          </div>
        )}
        {isError && (
          <NotFoundScreen
            title="The form you are looking for doesn't exist"
            message="Make sure you have the correct URL. Contact the form owner if the problem
                  persists."
            isEmbeded={isEmbeded}
          />
        )}
        {isSuccess && data !== null && <InquiryForm form={data} isEmbeded={isEmbeded} />}
      </div>
    </div>
  )
  //#endregion
}

export default Inquiry
